import React from "react";
import "./about.css";
import bgImg from "../assets/about.svg";
const About = () => {
  return (
    <div className="about w-full  py-16 px-4">
      <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-center uppercase">
        About Us
      </h1>
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img className="flex justify-center" src={bgImg} alt="/" />
        <div className="flex flex-col justify-center">
          <h2 className="text-center md:text-4xl sm:text-3xl text-2xl font-bold py-3 ">
            Systems Technologies?
          </h2>
          <p className="text-center text-justify">
            Systems Technologies Limited is a Nigerian Fintech firm with limited
            liability that was founded in 2020. It was created to assist in
            deploying technical solutions for the benefit of humanity. We handle
            mobile applications and websites for small businesses and offer a
            secure payment infrastructure for purchasing digital utility
            services in the simplest and most convenient ways; to help
            enterprises become profitable and beloved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
