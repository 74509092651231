import React, { useEffect, useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Conference from "./pages/Conference";

const App = () => {
  const routes = [
    // public Routes
    { path: "/", element: <Homepage />, WithLayout: false },
    { path: "/conference", element: <Conference />, WithLayout: false },
    // { path: '/terms', element: <TermsAndConditions />, WithLayout: false },
    // { path: '/faqs', element: <FAQs />, WithLayout: false },
    // { path: '/signin', element: <SignIn />, authPage: true },
    // { path: '/signout', element: <SignIn />, authPage: true },
    // { path: '/signup', element: <SignUp />, authPage: true },
    // { path: '/forgotPassword', element: <ForgotPassword />, authPage: true },
    // { path: '/resetPassword', element: <ResetPassword/>, ispublic: true },

    // Private Routes
    // { path: '/dashboard', element: <Dashboard />, WithLayout: true },
    // { path: '/airtime', element: <Airtime />, WithLayout: true },
    // { path: '/data', element: <Data />, WithLayout: true },
    // { path: '/cable', element: <Cable />, WithLayout: true },
    // { path: '/electric', element: <Electric />, WithLayout: true },
    // { path: '/settings', element: <Settings />, WithLayout: true, isPrivate: true },
    // { path: '/transactions', element: <Transactions />, WithLayout: true, isPrivate: true },
    // { path: '/ATMFunding', element: <ATMFunding />, WithLayout: true, isPrivate: true },
    // { path: '/MonnifyFunding', element: <MonnifyFunding />, WithLayout: true, isPrivate: true },
    // { path: '/*', element: <Page404 />, WithLayout: false },
  ];

  return (
    <Routes>
      {routes.map((route, idx) => {
        // if (route.WithLayout) {
        //     return route.isPrivate ? (
        //         <Route path={route.path} element={WithLayout(isPrivate(route.element))} key={idx} />
        //     ) : (
        //         <Route path={route.path} element={WithLayout(route.element)} key={idx} />
        //     );
        // } else {
        return <Route path={route.path} element={route.element} key={idx} />;
        // }
      })}
    </Routes>
  );
};

export default App;
