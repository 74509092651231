import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import chizzle from "../assets/chizzlelogo.png";
import clickpay from "../assets/clickpaylogo.png";
import dataup from "../assets/DataUplogo2.png";
import dodata from "../assets/dodatalogo.png";
import dublivtu from "../assets/dublivtulogo.png";
import xpresspay from "../assets/xpresspaylogo.png";
import tigmapay from "../assets/tigmapaylogo.png";
import taxMobil from "../assets/taxMobilogo.png";
import finbar from "../assets/finbar.png";
import datahead from "../assets/datahead.png";
import easysub from "../assets/easysub.png";
import maaszoompay from "../assets/maaszoompay.png";
import nadis from "../assets/nadis.png";
import paybills from "../assets/paydbills.png";
import paysleek from "../assets/paysleek2.png";
import slickpay from "../assets/SLICKPA.png";
import smartwallet from "../assets/smartwallet.png";
import testok from "../assets/testok.png";
import transac from "../assets/transac.png";
import yedeetee from "../assets/yudeetee.png";

const responsive = {
  0: { items: 4 },
  568: { items: 4 },
  1024: { items: 8 },
};

const items = [
  <div className="item" data-value="1">
    <img src={nadis} alt="fff" className=" h-[5rem]  " />
  </div>,
  <div className="item" data-value="2">
    <img src={yedeetee} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="3">
    <img src={transac} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="4">
    <img src={testok} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="5">
    <img src={paysleek} alt="fff" className=" h-[5rem] " />
  </div>,

  <div className="item" data-value="6">
    <img src={chizzle} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="7">
    <img src={clickpay} alt="fff" className=" h-[5rem] " />
  </div>,

  <div className="item" data-value="9">
    <img src={dodata} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="10">
    <img src={dublivtu} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="11">
    <img src={xpresspay} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="12">
    <img src={tigmapay} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="13">
    <img src={taxMobil} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="14">
    <img src={finbar} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="15">
    <img src={datahead} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="16">
    <img src={easysub} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="17">
    <img src={maaszoompay} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="18">
    <img src={paybills} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="19">
    <img src={slickpay} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="20">
    <img src={smartwallet} alt="fff" className=" h-[5rem] " />
  </div>,
  <div className="item" data-value="21">
    <img src={dataup} alt="fff" className=" h-[5rem] " />
  </div>,
];

const Partner = () => {
  return (
    <div className="Partners bg-slate-300 mt-8">
      <div className="container mx-auto py-8 ">
        {" "}
        <div className=" ">
          <h3 className="md:text-5xl text-center  sm:text-3xl text-2xl font-bold pb-8">
            Our Partners
          </h3>
        </div>
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="alternate"
          autoPlay
          autoPlayStrategy="none"
          autoPlayInterval={1000}
          animationDuration={1000}
          animationType="fadeout"
          infinite
          touchTracking={false}
          disableDotsControls
          disableButtonsControls
        />
      </div>
    </div>
  );
};

export default Partner;
