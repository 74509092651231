import React from "react";
import "./contact.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import bgImg from "../assets/contact.png";
import { IoMdCall } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
const Pricing = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ffj1lnr",
        "template_6hhtedw",
        form.current,
        "WTvAL-rRyCLVnoHr2"
      )
      .then(
        (result) => {
          console.log(alert("mail sent successfully"));
        },
        (error) => {
          console.log(alert("mail not successfully sent"));
        }
      );
    e.target.reset();
  };

  return (
    <div className="contact w-full py-4  px-4">
      <div className="p-4">
        <h1 className="md:text-5xl text-center  sm:text-3xl text-2xl font-bold ">
          Contact Us
        </h1>
      </div>
      <div className=" py-8  mx-auto   grid md:grid-cols-2">
        <div className="mb-3 w-full">
          <img
            className="h-[350px] w-[500px] md:h-[350px] "
            src={bgImg}
            alt="/"
          />
          <div className="">
            <div className="">
              <div className="icon flex mt-6">
                <div className="phone">
                  <IoMdCall className="Facebook ml-4" />
                </div>
                <div className="paragraph ml-4">
                  <p>+2347082106810</p>
                </div>
              </div>
              <div className="icon flex  mt-6">
                <div className="phone">
                  <BsWhatsapp className="Facebook  ml-4" />
                </div>
                <div className="paragraph ml-4">
                  <p>+2347082106810</p>
                </div>
              </div>
              <div className="icon flex mt-6">
                <div className="phone">
                  <MdEmail className="Facebook ml-4" />
                </div>
                <div className="paragraph ml-4">
                  <p>support@systemstech.com.ng</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-center xl:mt-1 sm:mt-16 ">
          <div className="contact_form w-full md:w-full">
            <form ref={form} onSubmit={sendEmail}>
              <div className="flex justify-center">
                <div className="mb-3 w-full">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label inline-block pb-2 mb-2 text-gray-700"
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    className="name
                  form-control
                  block
                  w-full
                  h-12 
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded-lg 
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                    id="exampleFormControlInput1"
                    placeholder="Full-Name"
                    name="user_name"
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <div className="mb-3 w-full ">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label inline-block  pb-2 mb-2  text-gray-700"
                  >
                    Your Email
                  </label>
                  <input
                    type="text"
                    className="email
 
        form-control
        block
        w-full
        h-12 
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded-lg 
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                    id="exampleFormControlInput1"
                    placeholder="Enter your email"
                    name="user_email"
                  />
                </div>
              </div>
              <div class="flex justify-center">
                <div class="mb-3 w-full">
                  <label
                    for="exampleFormControlTextarea1"
                    class="form-label inline-block  pb-2 mb-2 text-gray-700"
                  >
                    Message
                  </label>
                  <textarea
                    className="msg form-control
          block
          w-full
          h-60 
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded-lg 
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
          "
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Type your message"
                    name="user_message"
                  ></textarea>
                </div>
              </div>

              <div className=" mt-4 flex justify-between">
                <p></p>
                <button
                  type=""
                  className="btn px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight rounded-lg shadow-md hover:bg-purple-700 text-white hover:shadow-lg focus:bg-blue-700 text-white focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
