import { useEffect } from "react";
import flyer from "../assets/flyer.jpg";
import { Link, Link as LinkRef } from "react-router-dom";
import bgImg from "../assets/Component1.png";
import youtube_active from "../assets/youtube_active.png";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

function Homepage() {
  const timerProps = {
    isPlaying: true,
    size: 80,
    strokeWidth: 6,
  };

  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };
  // const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  // const endTime = 1671181200; // use UNIX timestamp in seconds

  return (
    <div className="font-Quicksand ">
      <LinkRef to="/">
        <img
          className="mx-6"
          src={bgImg}
          width={"6%"}
          style={{ marginTop: 24 }}
          alt="/"
        />

        <label className="text-black mx-4" style={{ fontSize: "1vw" }}>
          SYSTEMS TECHNOLOGIES
        </label>
      </LinkRef>
      <div>
        <div className=" text-3xl md:text-3xl   text-black font-bold text-center ">
          <p>
            <span className="md:inline-block md:mb-3">
              2022 Annual Conference
            </span>
          </p>
          <p style={{ marginBottom: 20 }}>
            <span className="font-normal ">
              The Rose that Grows on Concrete
            </span>
          </p>
        </div>
      </div>

      {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
        <CountdownCircleTimer
          {...timerProps}
          colors="#7E2E84"
          duration={60}
          initialRemainingTime={Math.floor(
            (1671181200 - Date.now() / 1000) / 86400
          )}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime(
                "days",
                Math.floor((1671181200 - Date.now() / 1000) / 86400)
              )}
            </span>
          )}
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors="#D14081"
          duration={34560}
          initialRemainingTime={
            Math.floor((1671181200 - Date.now() / 1000) / 3600) * 24 * 60
          }
          onComplete={(totalElapsedTime) => ({
            shouldRepeat: 86400,
          })}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime(
                "hours",
                Math.floor((1671181200 - Date.now() / 1000) / 3600)
              )}
            </span>
          )}
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors="#EF798A"
          duration={3600}
          initialRemainingTime={Math.floor(
            (1671181200 - Date.now() / 1000) % 3600
          )}
          onComplete={(totalElapsedTime) => ({
            shouldRepeat: 3600,
          })}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime(
                "minutes",
                Math.floor(((1671181200 - Date.now() / 1000) % 3600) / 60)
              )}
            </span>
          )}
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors="#218380"
          duration={60}
          initialRemainingTime={Math.floor(
            (1671181200 - Date.now() / 1000) % 60
          )}
          onComplete={() => ({
            shouldRepeat: 60,
          })}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime(
                "seconds",
                Math.floor((1671181200 - Date.now() / 1000) % 60)
              )}
            </span>
          )}
        </CountdownCircleTimer>
      </div> */}
      <a href="https://www.youtube.com/watch?v=whRzOfIUUXM">
        <p style={{ margin: 20 }}>
          <img src={youtube_active} width={"30%"} className="mx-auto"></img>
        </p>
      </a>

      <img src={flyer} width={"100%"} />
    </div>
  );
}

export default Homepage;
