import React from "react";
import Navbar from "./Navbar";
import Lottie from "react-lottie";
import homeAnimation from "../assets/home_animation";

const Hero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: homeAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className=" home w-screen h-screen  ">
      <Navbar />

      <div className=" mx-auto flex  md:flex-row flex-col items-center mt-16 md:mt-0 md:pt-0 ">
        <div className="md:w-1/2 md:px-9 text-center md:text-left ">
          <div className=" text-3xl md:text-6xl   text-white font-bold ">
            <span className="md:inline-block md:mb-3"> IT & Engineering</span>
            <br className="hidden lg:inline-block " />
            <span className="font-normal "> Solutions</span>
          </div>
          <div className="mb-8 leading-relaxed md:text-xl text-white md:mt-3">
            People and Solutions.
          </div>
        </div>
        <div className=" md:w-1/2 w-full">
          <div className="">
            <Lottie options={defaultOptions} />
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Hero;
