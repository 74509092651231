import React from "react";

import ceo from "../assets/ceo.png";
import director1 from "../assets/director1.png";
import director from "../assets/director.png";
import hr from "../assets/hr.png";
import cs from "../assets/cs.png";
import sec from "../assets/sec.png";
import it from "../assets/it.png";
import account from "../assets/account.png";

const Team = () => {
  return (
    <div className="team w-full mt-10">
      <div className="">
        <div className="px-4 py-12">
          <h2 className=" text-3xl md:text-5xl pt-8 text-black-300 uppercase text-center font-bold mb-6">
            Meet Our Team
          </h2>

          <div class="grid grid-cols-2 md:grid-cols-3 justify-items-center">
            <div class="flex flex-col items-center mb-8">
              <div className=" ">
                <div className="dropShadow   z-30 relative">
                  <div className="clippath">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <img className="h-auto max-w-full " src={ceo} alt="/" />
                    </div>
                  </div>
                  <div className=" top-0  right-[-20px] sm:right-[-30px]  clippath absolute z-[-10]">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem] mdx">
                      <div className="bg-slate-200 h-full w-full "> &nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="md:text-4xl  text-xl font-semibold  text-center relative mt-2">
                Yusuf, Yusuf Folawiyo
              </p>
              <p className="md:text-xl text-sm text-slate-500 mx-auto text-center z-50  ">
                Chief Executive Officer
              </p>
            </div>

            <div class="flex flex-col items-center">
              <div className=" ">
                <div className="dropShadow   z-30 relative">
                  <div className="clippath">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <img
                        className="h-auto max-w-full "
                        src={director}
                        alt="/"
                      />
                    </div>
                  </div>
                  <div className=" top-0  right-[-20px] sm:right-[-30px]  clippath absolute z-[-10]">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <div className="bg-slate-200 h-full w-full "> &nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="md:text-4xl  text-xl font-semibold  text-center relative mt-2">
                Abdulazeez, Yusuf Olawale
              </p>
              <p className="md:text-xl text-sm text-slate-500 mx-auto text-center z-50  ">
                Director
              </p>
            </div>

            <div class="flex flex-col items-center ">
              <div className=" ">
                <div className="dropShadow   z-30 relative">
                  <div className="clippath">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <img className="h-auto max-w-full " src={hr} alt="/" />
                    </div>
                  </div>
                  <div className=" top-0  right-[-20px] sm:right-[-30px]  clippath absolute z-[-10]">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <div className="bg-slate-200 h-full w-full "> &nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="md:text-4xl  text-xl font-semibold  text-center relative mt-2">
                Dere, Mariam Yetunde
              </p>
              <p className="md:text-xl text-sm text-slate-500 mx-auto text-center z-50  ">
                Human Resources Manager
              </p>
            </div>

            <div className="flex flex-col items-center  ">
              <div className=" ">
                <div className="dropShadow   z-30 relative">
                  <div className="clippath">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <img
                        className="h-auto max-w-full "
                        src={account}
                        alt="/"
                      />
                    </div>
                  </div>
                  <div className=" top-0  right-[-20px] sm:right-[-30px]  clippath absolute z-[-10]">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <div className="bg-slate-200 h-full w-full "> &nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="md:text-4xl  text-xl font-semibold  text-center mt-2">
                Tolulope, Mary Ogurinde
              </p>
              <p className="md:text-xl text-sm text-slate-500 mx-auto text-center  ">
                Head of Accounting
              </p>
            </div>

            <div class="flex flex-col items-center  ">
              <div className=" ">
                <div className="dropShadow   z-30 relative">
                  <div className="clippath">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <img className="h-auto max-w-full " src={it} alt="/" />
                    </div>
                  </div>
                  <div className=" top-0  right-[-20px] sm:right-[-30px]  clippath absolute z-[-10]">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <div className="bg-slate-200 h-full w-full "> &nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="md:text-4xl  text-xl font-semibold  text-center relative mt-2">
                Jimoh, Habeeb Oladoyin
              </p>
              <p className="md:text-xl text-sm text-slate-500 mx-auto text-center z-50  ">
                Head, IT Support
              </p>
            </div>
            <div class="flex flex-col items-center">
              <div className=" ">
                <div className="dropShadow   z-30 relative">
                  <div className="clippath">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem]">
                      <img className="h-auto max-w-full " src={cs} alt="/" />
                    </div>
                  </div>
                  <div className=" top-0  right-[-20px] sm:right-[-30px]  clippath absolute z-[-10]">
                    <div className="md:h-[17.375rem] md:w-[17.375rem] sm:h-[11rem] sm:w-[11rem] h-[7rem] w-[7rem] mdx">
                      <div className="bg-slate-200 h-full w-full "> &nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="md:text-4xl  text-xl font-semibold  text-center relative mt-2">
                Alade, Adeola James
              </p>
              <p className="md:text-xl text-sm text-slate-500 mx-auto text-center z-50  ">
                Customer Service Manager
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Team;
