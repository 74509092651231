import About from "../components/About";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Partner from "../components/Partner";
import Contact from "../components/Contact";
import Support from "../components/Support";
import Team from "../components/Team";

function Homepage() {
  return (
    <div className="font-Quicksand ">
      <Hero />
      <Support />
      <About />
      <Team />
      <Partner />
      <Contact />
      <Footer />
    </div>
  );
}

export default Homepage;
