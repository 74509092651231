import React from "react";
import { Link } from "react-scroll";
import bgImg from "../assets/Component1.png";
import "./footer.css";
import { MdEmail } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { IoMdCall } from "react-icons/io";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <div className=" footer w-full bg-gradient-to-l from-purple-900 to-transparent  text-gray-200  pt-20">
      <div className="flex flex-col sm:flex-row  justify-around">
        <div className="flex  sm:shrink-0 justify-center">
          <ul className="ul">
            <div className="img">
              <img className="logo mx-6" src={bgImg} width={80} alt="/" />
              <label className="text-white" style={{ fontSize: 11 }}>
                SYSTEMS TECHNOLOGIES
              </label>
            </div>
            <div className="icon1 py-2 flex">
              <div className="facebook px-3">
                <FaFacebook className="Facebook " />
              </div>
              <div className="instagram px-3">
                <FaInstagram className="Facebook" />
              </div>
              <div className="twitter px-3">
                <FaTwitter className="Facebook" />
              </div>
            </div>
            <div className="flex ">
              <div className="twit twitter ">
                <GoLocation className="Facebook mt-8 " />
              </div>
              <li className="text py-4 ml-2 sm:w-96">
                No, 1 Sasraj Plaza, Agric Estate, Sango,
                <br />
                Ilorin, Kwara State, Nigeria
              </li>
            </div>

            <div className="flex">
              <div className="message twitter">
                <MdEmail className="Facebook mt-6" />
              </div>
              <li className="text ml-2 mt-6"> support@systemStech.com.ng</li>
            </div>
            <div className="flex">
              <div className="call twitter">
                <IoMdCall className="Facebook mt-10" />
              </div>
              <li className="text py-2 ml-2 mt-8">+2347082106810</li>
            </div>
          </ul>
        </div>
        <div>
          <div className="flex p-4 mr-28 justify-center">
            <ul className="ul mb-4">
              <h1 className="text-2xl company font-bold py-5 uppercase">
                Company
              </h1>
              <li className="text l py-3">
                <Link to="home" smooth={true} duration={500}>
                  Home
                </Link>
              </li>
              <li className="text l py-2">
                <Link to="support" smooth={true} offset={-50} duration={500}>
                  what We Do
                </Link>
              </li>
              <li className="text l py-2">
                <Link to="about" smooth={true} offset={-200} duration={500}>
                  About Us
                </Link>
              </li>
              <li className="text l py-2">
                <Link to="team" smooth={true} offset={-100} duration={500}>
                  Our Team
                </Link>
              </li>
              <li className="text l py-2">
                <Link to="bout" smooth={true} offset={-100} duration={500}>
                  Our Partners
                </Link>
              </li>
              <li className="text l py-2">
                <Link to="contact" smooth={true} offset={-50} duration={500}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
