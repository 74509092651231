import React from "react";
import taxmobile from "../assets/taxmobile.png";
import datanow from "../assets/datanow.png";
import clickpay from "../assets/Clickpay2.png";

const Support = () => {
  return (
    <div className=" support w-full ">
      <div className=" mx-auto text-black relative">
        <div className="px-4 md:px-32 py-12 mb-12">
          <h2 className=" text-3xl md:text-5xl pt-8 text-black-300 uppercase text-center font-bold mb-6">
            Our Products
          </h2>
          <p className=" md:text-xl text-sm text-slate-500  text-center">
            Our team of engineers and programmers apply their deep knowledge of
            electrical codes, embedded systems and software development with
            their hands-on experience to design and implement cost-effective,
            intuitive, robust solutions for electrical engineering and software
            challenges.
          </p>

          <div className=" mt-20 w-full">
            <div className="  flex flex-col-reverse  md:flex-row">
              <div className="p-10">
                <img className=" " src={clickpay} alt="/" />
              </div>
              <div className=" flex flex-col justify-center ">
                <div className=" md:text-4xl  text-2xl font-semibold py-3 text-center relative     mt-10">
                  <h3 className="z-50 absolute inset-x-0 bottom-0 ">
                    {" "}
                    ClickPay
                  </h3>
                  <div className="absolute bottom-[75px] md:bottom-[-55px] left-[45%] h-24 md:h-64 z-0 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="76"
                      height="259"
                      viewBox="0 0 76 259"
                    >
                      <text
                        id="_1"
                        data-name="1"
                        transform="translate(38 207)"
                        fill="#d5d5d5"
                        font-size="207"
                        font-family="Quicksand-Bold, Quicksand"
                        font-weight="700"
                      >
                        <tspan x="-37" y="0">
                          1
                        </tspan>
                      </text>
                    </svg>
                  </div>
                </div>
                <p className=" md:text-xl text-sm text-slate-500 mx-auto text-center z-50  ">
                  Buy cheap and affordable data, airtime, cable TV subscription,
                  electricity bills payment at a go on ClickPay. ClickPay is
                  available on Google PlayStore and Apple Store.
                </p>
              </div>
            </div>
          </div>

          <div className=" mt-10 w-full">
            <div className="  flex flex-col-reverse  md:flex-row-reverse">
              <div className="p-10">
                <img className=" " src={datanow} alt="/" />
              </div>
              <div className=" flex flex-col justify-center ">
                <div
                  className=" md:text-4xl  text-2xl font-semibold py-3 text-center relative py
                -10 mt-10"
                >
                  <h3 className="z-50 absolute inset-x-0 bottom-0 ">
                    {" "}
                    DataNow
                  </h3>
                  <div className="absolute bottom-[60px] md:bottom-[-55px] left-[45%] h-28 md:h-64 z-0 font-Quicksand">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="110"
                      height="259"
                      viewBox="0 0 110 259"
                    >
                      <text
                        id="_3"
                        data-name="3"
                        transform="translate(55 207)"
                        fill="#d5d5d5"
                        font-size="207"
                        font-family="Quicksand-Bold, Quicksand"
                        font-weight="700"
                      >
                        <tspan x="-54" y="0">
                          2
                        </tspan>
                      </text>
                    </svg>
                  </div>
                </div>
                <p className=" md:text-xl text-sm text-slate-500 mx-auto text-center z-50 ">
                  DataNow offer Businesses premium services to boost their
                  digital utility sales such as airtime, data, cable TV and
                  electricity payments through an automated web service. API is
                  provided for seamless integration on other platforms.
                </p>
              </div>
            </div>
          </div>
          <div className=" mt-10 w-full">
            <div className="  flex flex-col-reverse  md:flex-row">
              <div className="p-10">
                <img className=" " src={taxmobile} alt="/" />
              </div>
              <div className=" flex flex-col justify-center ">
                <div
                  className=" md:text-4xl  text-2xl font-semibold py-3 text-center relative py
                -10 mt-10"
                >
                  <h3 className="z-50 absolute inset-x-0 bottom-0 ">
                    {" "}
                    TaxMobile.Online
                  </h3>
                  <div className="absolute bottom-[60px] md:bottom-[-55px] left-[45%] h-28 md:h-64 z-0 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="110"
                      height="259"
                      viewBox="0 0 110 259"
                    >
                      <text
                        id="_3"
                        data-name="3"
                        transform="translate(55 207)"
                        fill="#d5d5d5"
                        font-size="207"
                        font-family="Quicksand-Bold, Quicksand"
                        font-weight="700"
                      >
                        <tspan x="-54" y="0">
                          3
                        </tspan>
                      </text>
                    </svg>
                  </div>
                </div>
                <p className=" md:text-xl text-sm text-slate-500 mx-auto text-center z-50 ">
                  TaxMobile.online serve as a strategic partner to tax
                  practitioners, taxpayers and tax administrators, helping them
                  advance their tax knowledge by providing innovative,
                  integrated and subscriber-focused tax information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
